import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';

import SearchInput from '@/components/contract/create/elements/SearchInput/SearchInput.vue';
import ContractSheet from '@/components/contract/create/elements/ContractSheet/ContractSheet.vue';
import ResponsiblesForm from '@/components/contract/create/elements/ResponsiblesForm/ResponsiblesForm.vue';
import DirectAccessForm from '@/components/contract/create/elements/DirectAccessForm/DirectAccessForm.vue';

export default {
	name: 'BoxAssignment',
	props: {
		currentFile: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		tab: null,
		owner: null,
		documentType: {
			id: 1,
			name: 'Contrato',
			flag_contract_form: true,
		},
		formActive: 'signationForm',
		responsibles: [],
		contract_field: [],
		direct_access: [],
		isLoadingSave: false,
	}),
	computed: {
		...mapState('document', ['documentTypes']),

		result_selected: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		IsCompleteForm() {
			return this.owner && this.documentType /*&& this.responsibles.length > 0*/;
		},
		IsCompleteContract() {
			return (
				this.owner &&
				this.documentType &&
				/*this.responsibles.length > 0 &&*/
				this.contract_field.length > 0
			);
		},
	},
	created() {
		this.getDocumentType();
		this.getUsersActive();
	},
	methods: {
		...mapActions('document', ['listDocumentType', 'getTemplate']),
		...mapActions('security', ['getUsersActive']),
		...mapActions('contract', ['saveContract']),

		saveform() {
			if (this.documentType.flag_contract_form) {
				this.formActive = 'contractForm';
			} else {
				this.sendData();
			}
		},

		async getDocumentType() {
			const { ok, response } = await this.listDocumentType();
			if (ok && Array.isArray(response?.documentTypes)) {
				this.documentType = response?.documentTypes[0];
			}
			this.getFormat(this.documentType);
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},
		async sendData() {
			this.isLoadingSave = true;
			const data = {
				contract: {
					name: this.currentFile?.name,
					document_type_id: this.documentType?.id,
					owner_id: this.owner?.id,
					job_id: this.currentFile?.jobId,
					file: this.currentFile?.file,
					status: 'DRAFT',
					company_id: parseInt(localStorage.getItem('company_id')),
				},
				responsibles: this.responsibles || null,
				contract_field: this.contract_field || null,
				direct_access: this.direct_access || null,
			};
			const { ok, response, error } = await this.saveContract(data);
			if (ok) {
				this.dialog = false;
				this.$router.push({
					name: 'documentShow',
					params: {
						documentId: response?.data?.id,
					},
				});
				$EventBus.$emit('showSnack', 'success', response?.message[0]);
			} else {
				let msm = error?.data?.message;
				msm = Array.isArray(msm) ? msm.join(', ') : msm;
				this.showAlert('error', msm);
			}
			this.isLoadingSave = false;
		},

		getFormat(documentType) {
			if (documentType?.flag_contract_form) {
				this.getTemplate(documentType?.id);
			}
		},

		setContractField(v) {
			this.contract_field = v;
		},
		setDirectAccess(v) {
			this.direct_access = v;
		},
	},
	destroyed() {},
	components: {
		SearchInput,
		ContractSheet,
		ResponsiblesForm,
		DirectAccessForm,
	},
};
