<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:items-per-page="-1"
		hide-default-footer
		dense
	>
		<!-- <template v-slot:[`item.user`]="{ item }">
			<span v-text="getUserName(item.user)"></span>
		</template>
		<template v-slot:[`item.date`]="{ item }">
			<span v-text="formatDate(item.date)"></span>
		</template> -->
		<template v-slot:[`item.actions`]="{ item }">
			<v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
		</template>
	</v-data-table>
</template>
<script>
import moment from 'moment';
export default {
	name: 'LinkTable',
	data: () => ({
		headers: [
			{
				text: 'Elemento',
				value: 'type',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Ubicación',
				value: 'page',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],
	}),
	props: {
		value: {
			type: Array,
			required: false,
		},
		users: {
			type: Array,
			required: false,
		},
	},
	computed: {
		items: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		formatDate(date) {
			moment.locale('es');
			return date ? moment(date).format('LL') : null;
		},
		deleteItem(item) {
			const index = this.items.findIndex(
				(element) => element.currentId === item.currentId
			);
			if (index >= 0) {
				this.items.splice(index, 1);
			}
		},
	},
};
</script>
