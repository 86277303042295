<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:items-per-page="-1"
		hide-default-footer
		dense
	>
		<template v-slot:[`item.user_id`]="{ item }">
			<span v-text="getUserName(item.user_id)"></span>
		</template>
		<template v-slot:[`item.type`]="{ item }">
			<span v-text="getRole(item.type)"> </span>
		</template>
		<template v-slot:[`item.validity_from`]="{ item }">
			<span v-text="formatDate(item.validity_from)"></span>
		</template>
		<template v-slot:[`item.validity_to`]="{ item }">
			<span v-text="formatDate(item.validity_to)"></span>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
		</template>
	</v-data-table>
</template>
<script>
import moment from 'moment';
export default {
	name: 'ValidityTable',
	data: () => ({
		headers: [
			{
				text: 'Nombre',
				value: 'user_id',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Tipo',
				value: 'type',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Fecha inicio',
				value: 'validity_from',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Fecha fin',
				value: 'validity_to',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],
	}),
	props: {
		value: {
			type: Array,
			required: false,
		},
		users: {
			type: Array,
			required: false,
		},
	},
	computed: {
		items: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		formatDate(date) {
			moment.locale('es');
			return date ? moment(date).format('LL') : null;
		},
		deleteItem(item) {
			const index = this.items.findIndex(
				(element) => element.currentId === item.currentId
			);
			if (index >= 0) {
				this.items.splice(index, 1);
			}
		},
		getUserName(id) {
			const user = (this.users || []).find((user) => user.id == id);
			return user?.name || '';
		},
		getRole(expr) {
			switch (expr) {
				case 'USER':
					return 'Usuario';
				case 'MODERATOR':
					return 'Moderador';
				default:
					return '';
			}
		},
	},
};
</script>
