//import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import LinkTable from '@/components/contract/create/elements/LinkTable/LinkTable.vue';

export default {
	name: 'DirectAccessForm',
	props: ['value'],
	data: () => ({
		valid: null,
		linkForm: {
			type: null,
			page: null,
		},
		requiredRule: [(v) => !!v || 'Campo requerido'],
	}),
	computed: {
		direct_access: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {},
	methods: {
		generateId() {
			return `${performance.now()}${Math.random().toString().slice(5)}`.replace(
				'.',
				''
			);
		},
		addLink() {
			const isvalidated = this.$refs.accessForm.validate();
			if (isvalidated) {
				this.direct_access.push({
					...this.linkForm,
					page: parseInt(this.linkForm.page),
					currentId: this.generateId(),
				});
				this.$refs.accessForm.reset();
			}
		},
		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},
	},
	destroyed() {},
	components: {
		LinkTable,
	},
};
