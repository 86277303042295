import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'CreateOwnerDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			owner_form: {
				name: null,
				document: null,
				type: null,
			},
			pages: [{ page: 1, content: 'Cotenido 1' }],
			types: [
				{ value: 'PROVIDER', label: 'Proveedor' },
				{ value: 'CLIENT', label: 'Cliente' },
			],
		};
	},
	created() {},
	mounted() {},
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.cleanForm();
			}
		},
	},
	methods: {
		...mapActions('owner', ['saveOwner']),

		async sendOwner() {
			const isvalidated = this.$refs.addForm.validate();
			if (isvalidated) {
				this.loading = true;
				let data = {
					...this.owner_form,
					company_id: parseInt(localStorage.getItem('company_id')),
				};
				const { ok, response, error } = await this.saveOwner(data);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', response.message[0]);
					this.dialog = false;
				} else {
					this.showError(error);
				}
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		cleanForm() {
			this.$nextTick(() => {
				this.owner_form = {
					name: null,
					document: null,
					type: null,
				};
				if (this.$refs?.addForm) this.$refs.addForm.reset();
			});
		},
	},
	components: {},
};
