<template>
	<import-content />
</template>
<script>
import ImportContent from '@/components/contract/create/ImportContent/ImportContent.vue';

export default {
	name: 'CreateContractView',
	components: { ImportContent },
};
</script>
