// import { $EventBus } from '@/main';
import { mapState } from 'vuex';
import CDatePicker from '@/components/global/CDatePicker.vue';
import LinkTable from '@/components/contract/create/elements/LinkTable/LinkTable.vue';
import ValidityTable from '@/components/contract/create/elements/ValidityTable/ValidityTable.vue';
import moment from 'moment';

export default {
	name: 'responsiblesForm',
	props: {
		value: {
			type: Array,
			required: false,
		},
	},
	watch: {
		'permission_form.indefinite'(newVal) {
			if (newVal) {
				this.permission_form.validity_from = moment().format('YYYY-MM-DD');
				this.permission_form.validity_to = null;
			}
		},
	},

	data: () => ({
		valid: null,
		permission_form: {
			user_id: null,
			validity_from: null,
			validity_to: null,
			type: null,
			indefinite: false,
		},
		roles: [
			{ value: 'USER', text: 'Usuario' },
			{ value: 'MODERATOR', text: 'Moderador' },
		],
		requiredRule: [(v) => !!v || 'Campo requerido'],
		activeForm: false,
	}),
	computed: {
		...mapState('security', ['usersActive']),
		responsibles: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {},
	methods: {
		generateId() {
			return `${performance.now()}${Math.random().toString().slice(5)}`.replace(
				'.',
				''
			);
		},
		addPermission() {
			let isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				const isDuplicate = this.responsibles.some(
					(user) => user.user_id === this.permission_form.user_id
				);
				if (!isDuplicate) {
					this.responsibles.push({
						...this.permission_form,
						currentId: this.generateId(),
					});
					this.$refs.form.reset();
				}
			}
		},
	},
	destroyed() {},
	components: {
		CDatePicker,
		LinkTable,
		ValidityTable,
	},
};
