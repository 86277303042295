import { $EventBus } from '@/main';
import { mapActions } from 'vuex';

export default {
	name: 'InputPdf',
	props: {
		value: {
			type: Object,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		loading: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			defaultButtonText: 'Importar documento',
			isSelecting: false,
		};
	},
	created() {},
	mounted() {},
	computed: {
		document: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		buttonText() {
			return this.label || this.defaultButtonText;
		},
	},
	watch: {},
	methods: {
		...mapActions('contract', ['uploadFile']),

		onButtonClick() {
			this.isSelecting = true;
			window.addEventListener(
				'focus',
				() => {
					this.isSelecting = false;
				},
				{ once: true }
			);

			this.$refs.uploader.click();
		},
		hasExtension(file) {
			let exts = ['.pdf'];
			let fileName = file?.name;
			const parts = fileName.split('.');
			const extension = parts.pop().toLowerCase();
			const baseName = parts.join('.');
			fileName = baseName + '.' + extension;
			return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(
				fileName
			);
		},
		onFileChanged(e) {
			const files = [...e.target.files];
			if (Array.isArray(files)) {
				files.forEach((file) => {
					if (this.hasExtension(file)) {
						this.defaultButtonText = file.name;
						this.upload({
							name: file.name,
							file: file,
							url: URL.createObjectURL(file),
						});
					} else {
						$EventBus.$emit(
							'showSnack',
							'warning',
							'Seleccione el formato de archivo correcto'
						);
					}
				});
			}
			// do something
		},
		async upload(document) {
			this.$emit('setLoading', true);
			this.errorApi = [];
			let formData = new FormData();
			formData.append('file', document?.file, document?.name);
			const { ok, response, error } = await this.uploadFile(formData);
			if (ok) {
				this.document = {
					...document,
					file: response.data.file,
					jobId: response.data.jobId,
				};
				$EventBus.$emit('showSnack', 'success', response?.message[0]);
			} else {
				this.document = {};
				this.showError(error);
			}
			this.$emit('setLoading', false);
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},
	},
	components: {},
};
