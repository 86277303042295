import InputFile from '@/components/global/InputFile/InputFile.vue';
import BoxAssignment from '@/components/contract/create/BoxAssignment/BoxAssignment.vue';
//import { mapActions } from 'vuex';

export default {
	name: 'ImportContent',
	props: {},
	data: () => ({
		result_selected: null,
		currentFile: {},
		loading: false,
	}),
	computed: {
		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Legal',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractHome',
					},
				},
				{
					text: 'Creación de Documentos:',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	watch: {
		// currentFile: {
		// 	handler() {
		// 		this.setIndicators();
		// 	},
		// 	deep: true,
		// },
	},
	created() {},
	methods: {
		setLoading(value) {
			this.loading = value;
		},
		saveContract(data = {}) {
			data.contract = {
				...data.contract,
				name: this.currentFile.name,
				job_id: this.currentFile.jobId,
				file: this.currentFile.file,
			};
		},
	},
	destroyed() {},
	components: {
		InputFile,
		BoxAssignment,
	},
};
