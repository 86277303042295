//import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import CDatePicker from '@/components/global/CDatePicker.vue';
import ValidityTable from '@/components/contract/create/elements/ValidityTable/ValidityTable.vue';
import LinkTable from '@/components/contract/create/elements/LinkTable/LinkTable.vue';
import ContractSheetDialog from '@/components/contract/create/elements/ContractSheetDialog/ContractSheetDialog.vue';

export default {
	name: 'ContractSheet',
	props: ['value'],
	data: () => ({
		links: [],
		tab: null,
		valid: null,
		responses: [],
		isActiveDialog: false,
		linkForm: {
			linkType: null,
			pageNumber: null,
		},
		linkTypes: {},
		items: ['Ficha Contractual', 'Accesos directos'],
		requiredRule: [(v) => !!v || 'Campo requerido'],
	}),
	computed: {
		contractField: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {},
	methods: {
		generateId() {
			return `${performance.now()}${Math.random().toString().slice(5)}`.replace(
				'.',
				''
			);
		},
		addLink() {
			this.links.push({
				...this.linkForm,
				currentId: this.generateId(),
			});
		},
		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},
		setContractField(v) {
			this.contractField = v;
		},
	},
	destroyed() {},
	components: {
		CDatePicker,
		ValidityTable,
		LinkTable,
		ContractSheetDialog,
	},
};
